.App {
  padding-bottom: 2em;
}
.content {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pointer {
  cursor: pointer;
}

.limitHeader {
  height: 4em;
  overflow: hidden;
}

.bottom {
  position: absolute;
  bottom: 1rem;
}

.nl2br {
  white-space: pre-wrap;
}
